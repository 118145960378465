import { ListTeaser as ChameleonListTeaser } from '@mediahuis/chameleon-react';
import { ComponentType, FC } from 'react';

import { getThemeDataAttributes } from '@hubcms/utils-theme';

import { ListTeaserProps as ChameleonListTeaserProps } from '../../domain/chameleon/list-teaser-props';
import { TeaserLink } from '../TeaserLink';

import { ListTeaserProps } from './types';
import { getChameleonProps } from './getChameleonProps';

const ListTeaser: FC<ListTeaserProps> = props => {
  const Component = ChameleonListTeaser as ComponentType<ChameleonListTeaserProps>;

  let link = props.link;
  if (typeof props.link === 'string' && props.trackingData) {
    link = <TeaserLink url={props.link} isExternal={props.isExternal} trackingData={props.trackingData} />;
  }

  const chameleonProps = getChameleonProps(props);

  const themeDataAttributes = props.theme ? getThemeDataAttributes(props.theme) : null;
  return (
    <Component
      {...chameleonProps}
      link={link}
      data-article-id={props.id}
      data-list-id={props.list?.id}
      {...themeDataAttributes}
    />
  );
};

export default ListTeaser;
